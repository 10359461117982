import { Injectable } from '@angular/core';
import { ConfigDataResponse } from '../../shared/services/api/config.service';
import { PermissionAction, permission } from './authentication.service';
import { Brand } from '../../shared/services/api/brand.service';
import { Category } from '../../shared/services/api/category.service';
import { Embellishment } from '../../shared/services/api/embellishment.services';
import { Attribute } from '../../shared/services/api/attribute.services';
import CryptoES from 'crypto-es';
import { environment } from '../../../environments/environment';
import { Route, Router } from '@angular/router';

const { AES, enc } = CryptoES;
@Injectable()
export class StorageManagerService {
    private static readonly TOKEN: string = 'token';
    private static readonly CONFIG: string = 'config';
    private static readonly BRAND: string = 'brand';
    private static readonly CATEGORY: string = 'category';
    private static readonly PERMISSION: string = 'permission';
    private static readonly EMBELLISHMENT: string = 'embellishment';
    private static readonly ATTRIBUTE: string = 'attribute';
    static secretKey = environment.jwtSecretKey;

    static encryptData(data: any): string {
        const jsonString = JSON.stringify(data);
        const encrypted = AES.encrypt(jsonString, this.secretKey).toString();

        return encrypted;
    }

    static decryptData(encryptedData: string): any {
        try {
            const bytes = AES.decrypt(encryptedData, this.secretKey);
            const decryptedData = bytes.toString(enc.Utf8);
            return JSON.parse(decryptedData);
        } catch (error) {
            this.clearAll();
            return null;
        }
    }

    static storeToken(token: Token) {
        localStorage.setItem(this.TOKEN, JSON.stringify(token));
    }
    static storePermissions(permissions: permission) {
        Object.entries(permissions.permissions).forEach(
            ([permission, details]) => {
                if (details[PermissionAction.MANAGE]) {
                    details[PermissionAction.VIEW] = true;
                } else if (!details.hasOwnProperty('view')) {
                    details[PermissionAction.VIEW] = false;
                }
            }
        );
        const encryptedpermissions = this.encryptData(permissions);
        localStorage.setItem(this.PERMISSION, encryptedpermissions);
    }
    static getPermissions() {
        const getPermissions = localStorage.getItem(this.PERMISSION);
        if (getPermissions) {
            return this.decryptData(getPermissions);
        }
        return null;
    }

    static getToken() {
        const tokenString = localStorage.getItem(this.TOKEN);
        if (tokenString !== null) {
            return JSON.parse(tokenString);
        }
        return null;
    }

    static storeUser(user: User) {
        const encryptedUser = this.encryptData(user);
        localStorage.setItem('user', encryptedUser);
    }

    static getUser() {
        const userString = localStorage.getItem('user');
        if (userString !== null) {
            return this.decryptData(userString);
        }
        return null;
    }

    static storeConfig(config: ConfigDataResponse) {
        localStorage.setItem(this.CONFIG, JSON.stringify(config));
    }
    static storeEmbellishment(embellishment: Embellishment[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            embellishment,
        };
        sessionStorage.setItem(this.EMBELLISHMENT, JSON.stringify(cacheEntry));
    }
    static getEmbellishment() {
        const embellishmentString = sessionStorage.getItem(this.EMBELLISHMENT);
        if (embellishmentString !== null) {
            const data = JSON.parse(embellishmentString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.embellishment;
            }
        }
        return null;
    }
    static storeAttributes(attributes: Attribute[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            attributes,
        };
        sessionStorage.setItem(this.ATTRIBUTE, JSON.stringify(cacheEntry));
    }
    static getAttributes() {
        const attributeString = sessionStorage.getItem(this.ATTRIBUTE);
        if (attributeString !== null) {
            const data = JSON.parse(attributeString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.attributes;
            }
        }
        return null;
    }

    static storeBrand(brand: Brand[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            brand,
        };
        sessionStorage.setItem(this.BRAND, JSON.stringify(cacheEntry));
    }

    static getBrand() {
        const brandString = sessionStorage.getItem(this.BRAND);
        if (brandString !== null) {
            const data = JSON.parse(brandString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.brand;
            }
        }
        return null;
    }
    static storeCategory(catagory: Category[]) {
        const cacheEntry = {
            timestamp: new Date().toISOString(),
            catagory,
        };
        sessionStorage.setItem(this.CATEGORY, JSON.stringify(cacheEntry));
    }
    static getCategory() {
        const categoryString = sessionStorage.getItem(this.CATEGORY);
        if (categoryString !== null) {
            const data = JSON.parse(categoryString);
            const lastSyncAt = new Date(data.timestamp);
            const currentTime = new Date();
            const threeHours = 3 * 60 * 60 * 1000;
            if (currentTime.getTime() - lastSyncAt.getTime() < threeHours) {
                return data.catagory;
            }
        }
        return null;
    }
    static getConfig() {
        const configString = localStorage.getItem(this.CONFIG);
        if (configString !== null) {
            return JSON.parse(configString);
        }
        return null;
    }

    static clearAll() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
    }
}

export interface Token {
    token?: string;
    refreshToken?: string;
    expiredTime?: number;
}

export interface User {
    id: number;
    supplierId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
    status: string;
}
